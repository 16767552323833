/*
 *
 * */

import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
  filterHandlers = {
    update: 'dateBriefFilter/updateFilter',
    reset: 'dateBriefFilter/resetFilter',
    resetState: 'dateBriefFilter/resetFilterState',
    removeSelected: 'dateBriefFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setClassName('fullName', 'col-1-row-1');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setAction('fullName', 'dateBriefFilter/updateSearch');

    filter.createSearchEntity('market');
    filter.setPlaceholder('market', 'По объекту');
    filter.setName('market', 'search_fullMarketName');
    filter.setPreviewName('market', 'Объект');
    filter.setClassName('market', 'col-1-row-2');
    filter.setIconClassName('market', 'icon-store');
    filter.setAction('market', 'dateBriefFilter/updateSearch');

    filter.createMultiSelectEntity('statuses');
    filter.setTitle('statuses', 'По статусу');
    filter.setName('statuses', 'multi_select_statuses');
    filter.setPreviewName('statuses', 'По статусу');
    filter.setList('statuses', [
      { id: 'is_planned', name: 'Запланирован', checked: false },
      { id: 'refused_at_this_time', name: 'Отказался/время', checked: false },
      { id: 'refused_in_this_market', name: 'Отказался/место', checked: false },
      { id: 'came_out', name: 'Вышел', checked: false },
      { id: 'did_not_go_out', name: 'Не вышел', checked: false },
      { id: 'fast_replacement', name: 'Срочная замена', checked: false },
      { id: 'fake_call', name: 'Ложный вызов', checked: false },
      { id: 'reserve', name: 'Резерв', checked: false },
    ]);
    filter.setClassName('statuses', 'col-1-row-3');
    filter.setIconClassName('statuses', 'icon-status');
    filter.setAction('statuses', 'dateBriefFilter/updateMultiSelect');

    filter.createSelectEntity('clientId');
    filter.setTitle('clientId', 'По клиенту');
    filter.setName('clientId', 'select_client');
    filter.setPreviewName('clientId', 'Клиент');
    filter.setInitAction('clientId', 'dateBrief/initClients');
    filter.setAction('clientId', 'dateBriefFilter/updateSelect');
    filter.setIconClassName('clientId', 'icon-clients');
    filter.setClassName('clientId', 'col-2-row-1');
    filter.setClearAction('clientId', 'dateBriefFilter/resetSelect');
    filter.setSearchEnabled('clientId', true);

    filter.createSelectEntity('regionId');
    filter.setTitle('regionId', 'По региону');
    filter.setName('regionId', 'select_region');
    filter.setPreviewName('regionId', 'Регион');
    filter.setInitAction('regionId', 'dateBrief/initRegions');
    filter.setAction('regionId', 'dateBriefFilter/updateSelect');
    filter.setIconClassName('regionId', 'icon-compass');
    filter.setClassName('regionId', 'col-2-row-2');
    filter.setClearAction('regionId', 'dateBriefFilter/resetSelect');
    filter.setSearchEnabled('regionId', true);

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'dateBriefFilter/updateCheckbox');
    filter.setClassName('isNotTest', 'col-1-row-4 cp-padding__t-4');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    filter.createSelectEntity('partnerUuid');
    filter.setTitle('partnerUuid', 'По партнёру');
    filter.setName('partnerUuid', 'select_partnerUuid');
    filter.setPreviewName('partnerUuid', 'По партнёру');
    filter.setInitAction('partnerUuid', 'dateBrief/initPartnersFilter');
    filter.setClassName('partnerUuid', 'col-2-row-3');
    filter.setIconClassName('partnerUuid', 'icon-partners');
    filter.setAction('partnerUuid', 'dateBriefFilter/updateSelect');
    filter.setSearchEnabled('partnerUuid', true);
    watch(
      () => UserModule.securityGroup?.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partnerUuid', !isSupervisor);
      },
      { immediate: true }
    );

    this.filterModel = filter.filterModel;
  }
}
