
import { Component, Vue, Watch } from '@/lib/decorator';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import FilterPreview from '@/components/FilterPreview.vue';
import FilterBlock from '@/components/Filter.vue';
import AppFilterClearBtn from '@/layouts/partials/AppFilterClearBtn.vue';
import PaginationBlock from '@/components/Pagination.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';

import DateSwitcher from '@/components/DateSwitcher.vue';
import ModalBlock from '@/components/Modal.vue';
import EditPenalty from './_editPenalty.vue';
import EditBonus from './_editBonus.vue';

import UserModule from '@/store/user';
import FinanceModule from '@/store/finance/index';
import DateBriefModule from '@/store/finance/dateBrief';
import ShopShiftsModule from '@/store/shops/shift';
import DateBriefEntity from '@/store/finance/dateBrief/entity';
import { EventBus } from '@/lib/event-bus';
import AppTable from '@/components/ui/table/Table.vue';
import { dateFormat, minutesToTime } from '@/lib/Utils';
import IconFont from '@/components/icons/IconFont.vue';
import TextMoney from '@/components/table-items/TextMoney.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import AppTableColumnContext from '@/components/ui/table/TableColumnContext.vue';
import { EventChangeSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { ShiftModalParamsInterface } from '@/store/shops/shiftModalParams';
import { ShiftEmployeeStatus } from '@/lib/util/consts';
import ShiftModal from '@/views/shops/shift/form/shiftModal.vue';
import TimeFormatter from '@/components/TimeFormatter.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    TimeFormatter,
    AppCol,
    AppRow,
    AppTableColumnContext,
    TextDatetime,
    TextPhone,
    TextMoney,
    IconFont,
    AppTable,
    TabsNav,
    FilterPreview,
    FilterBlock,
    AppFilterClearBtn,
    PaginationBlock,
    DateSwitcher,
    ModalBlock,
    EditPenalty,
    AppHeaderMain,
    EditBonus,
    ShiftModal,
  },
})
export default class DateBriefMain extends Vue {
  headers: TableHeaderInterface[] = [];
  hiddenHeaders: TableHeaderInterface[] = [];
  modalNotification = '';
  hintMessage = '';
  availableClients = new Set<number>();

  isShowShiftModal = false;
  shopId?: string;
  serviceTypeId?: string;
  shiftIdForModal?: string;

  readonly shiftEmployeeStatus = ShiftEmployeeStatus;

  get isSupervisor(): boolean {
    return UserModule.isSupervisor;
  }

  get dataLoaded(): boolean {
    return DateBriefModule.dataLoaded;
  }

  get isSettingColumn(): boolean {
    return DateBriefModule.isSettingColumn;
  }

  get tabsNav(): TabsNavInterface {
    return FinanceModule.tabsNav;
  }

  get currentTab(): string {
    return FinanceModule.allTabs.date_brief?.id;
  }

  get settings(): PageInterface {
    return DateBriefModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return DateBriefModule.filter.filterSettings;
  }

  get date(): string {
    return DateBriefModule.filterDate;
  }

  get modalParamsMarket(): ShiftModalParamsInterface {
    return ShopShiftsModule.modalParams;
  }

  get modalParamsPenalty(): Record<string, Record<string, string | boolean | Record<string, string>>> {
    return DateBriefEntity.modalParams;
  }

  get penaltyData(): Record<string, string> {
    return DateBriefEntity.penaltyData;
  }

  get statuses(): Record<string, string> {
    return ShopShiftsModule.statuses;
  }

  get userCanEditPartner(): boolean {
    return UserModule.userHasPermission('CAN_EDIT_PARTNER');
  }

  @Watch('filterSettings.filterModel.clientId.list')
  getAvailableClients(clients: { id: number }[]): void {
    this.availableClients = clients.reduce((clientIds, { id }) => clientIds.add(id), new Set<number>());
  }

  @Watch('settings.table.hiddenTitles')
  getHiddenTitles(hiddenTitles: TableHeaderInterface[]): void {
    this.hiddenHeaders = [...hiddenTitles];
  }

  @Watch('settings.table.titles')
  getTitles(titles: TableHeaderInterface[]): void {
    this.headers = [...titles];
  }

  async resetFilters(): Promise<void> {
    await DateBriefModule.setPageToBegin();
    await DateBriefModule.clearSort();
    await DateBriefModule.filter.resetFilter();
    await DateBriefModule.getList();
  }

  async updateList(): Promise<void> {
    await DateBriefModule.setPageToBegin();
    await DateBriefModule.filter.updateFilter();
    await DateBriefModule.getList();
  }

  getTime(minutes: string): string {
    return minutesToTime(Number(minutes));
  }

  checkCanReadClient(clientId: number): boolean {
    return this.availableClients.has(clientId) && UserModule.userHasPermission('CAN_READ_CLIENT');
  }

  checkCanReadShiftTemplate(clientId: number): boolean {
    return this.checkCanReadClient(clientId) && UserModule.userHasPermission('CAN_READ_SHIFT_TEMPLATE');
  }

  checkIsOwnPartner(partnerUuid: string): boolean {
    return UserModule.partner?.uuid === partnerUuid;
  }

  handlePrevAction(): void {
    DateBriefModule.switchToPrevDate();
  }

  handleNextAction(): void {
    DateBriefModule.switchToNextDate();
  }

  updateDate(date: { day: string; month: string; year: string }): void {
    DateBriefModule.updateDate(`${date.day}-${date.month}-${date.year}`);
  }

  editShift(): void {
    ShopShiftsModule.initStatuses();
    ShopShiftsModule.showModal('edit');
  }

  setPenaltyData(data: { key: string; value: string }): void {
    DateBriefEntity.updatePenaltyData(data);
  }

  selectAmount(value: string): void {
    DateBriefModule.updatePageAmountItems(value);
  }

  closeModal(): void {
    ShopShiftsModule.hideModal('edit');
    DateBriefEntity.closeModalPenalty();
    DateBriefEntity.closeModalBonus();
  }

  savedEditShift(): void {
    DateBriefModule.getList();
  }

  toSettings(): void {
    DateBriefModule.updateIsSettingsColumn(true);
  }

  closeSettings(): void {
    DateBriefModule.updateIsSettingsColumn(false);
  }

  openShift(item: { marketId: number; serviceTypeId: number; marketShiftId: number }): void {
    this.shopId = item.marketId?.toString();
    this.serviceTypeId = item.serviceTypeId?.toString();
    this.shiftIdForModal = item.marketShiftId.toString();
    this.isShowShiftModal = true;
  }

  editBonus(item: Record<string, string> & { isBonusPaid: boolean }): void {
    const { start, end, marketShiftStart, marketShiftEnd } = item;
    const { dateFormat, timeFormat } = this;

    const shiftDate = dateFormat(start || marketShiftStart);
    const time = `${timeFormat(start || marketShiftStart)} - ${end || marketShiftEnd}`;

    this.setPenaltyData({ key: 'marketShiftId', value: item.marketShiftId });
    this.setPenaltyData({ key: 'bonus', value: item.bonusId });
    this.setPenaltyData({ key: 'employeeShift', value: item.employeeShiftId });
    this.setPenaltyData({ key: 'fullName', value: item.fio });
    this.setPenaltyData({ key: 'shiftTime', value: time });
    this.setPenaltyData({ key: 'shopAddress', value: item.market });
    this.setPenaltyData({ key: 'shiftDate', value: shiftDate });

    DateBriefEntity.setModelValue({ key: 'isBonusPaid', value: item.isBonusPaid });
    DateBriefEntity.setModelValue({ key: 'amount', value: item.bonusMoney });
    DateBriefEntity.setModelValue({
      key: 'comment',
      value: item.penaltyMoney && item.penaltyComment ? (item.penaltyComment as string) : '',
    });

    this.hintMessage = parseInt(item.penaltyMoney) ? 'ВНИМАНИЕ! Недостача сотрудника в этом периоде будет УДАЛЕНА' : '';

    DateBriefEntity.showModalBonus();
  }

  async editPenalty(item: Record<string, string>): Promise<void> {
    const penaltyDetail = await DateBriefEntity.getPenaltyDetail(item.employeeShiftId);

    const { start, end, marketShiftStart, marketShiftEnd } = item;
    const { dateFormat, timeFormat } = this;

    const shiftDate = dateFormat(start || marketShiftStart);
    const time = `${timeFormat(start || marketShiftStart)} - ${timeFormat(end || marketShiftEnd)}`;

    this.setPenaltyData({ key: 'marketShiftId', value: item.marketShiftId });
    this.setPenaltyData({ key: 'employeeShift', value: item.employeeShiftId });
    this.setPenaltyData({ key: 'fullName', value: item.fio });
    this.setPenaltyData({ key: 'shiftTime', value: time });
    this.setPenaltyData({ key: 'shopAddress', value: item.market });
    this.setPenaltyData({ key: 'shiftDate', value: shiftDate });
    this.setPenaltyData({ key: 'paymentDivided', value: item.paymentDivided });

    // @ts-ignore
    DateBriefEntity.setModelValue({ key: 'amount', value: parseInt(item.penaltyMoney) > 0 ? item.penaltyMoney : 0 });
    DateBriefEntity.setModelValue({ key: 'weeks', value: parseInt(item.penaltyMoney) > 0 ? penaltyDetail.parts : null });
    DateBriefEntity.setModelValue({
      key: 'comment',
      value: item.penaltyMoney && item.penaltyComment ? item.penaltyComment : '',
    });

    this.hintMessage = parseInt(item.bonusMoney) ? 'ВНИМАНИЕ! Премия сотрудника в этом периоде будет УДАЛЕНА' : '';

    DateBriefEntity.showModalPenalty();
  }

  sort(header: { id: string; sort: { value: string } }): void {
    DateBriefModule.sort({ field: header.id, sort: header.sort.value });
  }

  timeFormat(cell: string): string {
    return dateFormat(cell, { to: 'H:mm' });
  }

  dateFormat(cell: string): string {
    return dateFormat(cell, { to: 'DD.MM.YYYY' });
  }

  changeVisibleHeaders(e: EventChangeSortHeader): void {
    const tmphiddenTitles = [...this.hiddenHeaders];
    const tmpTitles = e.headers;
    let element;
    switch (e.changed.type) {
      case 'update':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.headers = tmpTitles;

        return;
      case 'add':
        element = tmphiddenTitles[e.changed.oldIndex];
        tmphiddenTitles.splice(e.changed.oldIndex, 1);
        tmpTitles.splice(e.changed.newIndex, 0, element);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
      case 'remove':
        element = tmpTitles[e.changed.oldIndex];
        tmpTitles.splice(e.changed.oldIndex, 1);
        tmphiddenTitles.splice(e.changed.newIndex, 0, element as never);
        this.hiddenHeaders = tmphiddenTitles;
        this.headers = tmpTitles;

        return;
    }
  }

  startDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.addEventListener('dragover', this.dragenter, { capture: true });
    element.addEventListener('dragleave', this.dragleave, { capture: false });
  }

  endDrag(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.removeEventListener('dragover', this.dragenter);
    element.removeEventListener('dragleave', this.dragleave);
  }

  dragenter(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.add('target-hover');
  }

  dragleave(): void {
    if (!('target' in this.$refs)) {
      return;
    }

    const target = this.$refs.target as { $refs: { container: { $el: HTMLElement } } };
    const element = target.$refs.container.$el;
    element.classList.remove('target-hover');
  }

  saveColumnSettings(): void {
    DateBriefModule.saveSettingsToStore({
      hidden: this.hiddenHeaders,
      visible: this.headers,
    });
  }

  cancelColumnSettings(): void {
    this.closeSettings();

    this.hiddenHeaders =
      DateBriefModule.hiddenTitles && DateBriefModule.hiddenTitles.length
        ? [...DateBriefModule.hiddenTitles]
        : 'hiddenTitles' in this.settings.table && Array.isArray(this.settings.table.hiddenTitles)
        ? [...this.settings.table.hiddenTitles]
        : [];

    this.headers =
      DateBriefModule.orderTitles && DateBriefModule.orderTitles.length
        ? [...DateBriefModule.orderTitles]
        : 'titles' in this.settings.table && Array.isArray(this.settings.table.titles)
        ? [...this.settings.table.titles]
        : [];
  }

  mounted(): void {
    FinanceModule.initTabsItems();

    EventBus.$on('savedShift', this.savedEditShift);
    DateBriefModule.init();
  }

  unmounted(): void {
    EventBus.$off('savedShift', this.savedEditShift);
  }
}
