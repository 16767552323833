import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

/**
 * Returns processed finance date brief info for date brief table
 *
 * @param {TableApiInterface} table shops data
 * @param options
 * @returns {Object} tableInfo
 */
export function prepareList(
  table: TableApiInterface,
  options?: { order?: TableTitle[]; hidden?: TableTitle[]; sort?: any[] }
): TableInterface {
  const uiTable = new UITable(table as any);

  return uiTable
    .removeColumn('end')
    .removeColumn('shiftDate')
    .removeColumn('start')
    .removeColumn('penaltyMoneyOrigin')
    .removeColumn('bonusMoneyOrigin')
    .removeColumn('_actions')
    .removeColumn('penaltyComment')
    .removeColumn('isTest')
    .removeColumn('isTestClient')
    .removeColumn('isTestMarket')
    .removeColumn('unitType')
    .removeColumn('quantity')

    .setProperty('penaltyMoney', 'align', 'right')
    .setProperty('earnedMoney', 'align', 'right')
    .setProperty('worked', 'align', 'right')
    .setProperty('worked', 'name', 'Выработка')
    .setProperty('bonusMoney', 'align', 'right')
    .setProperty('market', 'name', 'Объект')

    .addColumnByIndex(4, { id: 'partnerLegalName', name: 'Партнер', visible: true })
    .addColumnByIndex(5, { id: 'time', name: 'Время', visible: true })

    .setSortableValues(options && options.sort)
    .setHiddenTitles(options && options.hidden)
    .setOrderTitles(options && options.order)

    .getTable() as any;
}
