<template>
  <form ref="form" class="date-brief-form cp-margin__t--small" @submit.prevent="save">
    <div class="cp-form__row cp-page__padding-sm">
      <div class="cp-form__row cp-shift-position-info__wrapper cp-shift-info-icon__wrapper">
        <icon-font class="" size="20" icon="employee" />

        <span v-html="penaltyData.fullName"></span>
      </div>

      <div class="cp-form__row cp-shift-store-info__wrapper cp-shift-info-icon__wrapper">
        <icon-font class="" size="20" icon="store" />

        <span>{{ penaltyData.shopAddress }}</span>
      </div>

      <div class="cp-form__row cp-shift-store-info__wrapper cp-flex--align-end cp-shift-info-icon__wrapper">
        <icon-font class="" size="20" icon="alarm-clock" />

        <span>{{ penaltyData.shiftDate }} ({{ penaltyData.shiftTime }})</span>
      </div>
    </div>

    <div class="cp-form__row cp-page__padding-sm cp-margin__t--small">
      <form-input
        v-model="amount"
        :hint="hintMessage"
        label="Сумма задолженности, руб"
        type="number"
        mask="########"
        required
      />

      <form-input v-if="!penaltyData.paymentDivided" v-model="weeks" label="Срок отработки, недель" required />

      <form-input v-model="comment" label="Комментарий" />
    </div>

    <modal-btn-bottom position="between">
      <form-button :text="text.btnDelete" :bordered="true" :outline="true" @click="remove" class="cp-margin__r--sx" />

      <form-button :text="text.btnSave" :outline="true" type="submit" />
    </modal-btn-bottom>
  </form>
</template>

<script>
import { Component, Vue, Prop } from '@/lib/decorator';
import { ref } from 'vue';

import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { strings } from '@/lib/stringConst';
import FormInput from '@/components/form/Input/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';

import DateBriefEntity from '@/store/finance/dateBrief/entity';

@Component({
  components: {
    ModalBtnBottom,
    IconFont,
    FormInput,
    FormButton,
  },
})
export default class EditPenalty extends Vue {
  @Prop() penaltyData;
  @Prop() hintMessage;

  form = ref(null);
  validate = formValidate(this.form);

  amount = +this.model.amount || '';
  weeks = this.model.weeks ?? '';
  comment = this.model.comment ?? '';

  get text() {
    return strings;
  }

  get model() {
    return DateBriefEntity.model;
  }

  async save() {
    const valid = await this.validate.validate();
    const weeks = this.penaltyData?.paymentDivided ? '1' : this.weeks;

    if (!valid) {
      return;
    }

    DateBriefEntity.saveUpdate({
      type: 'penalty',
      textFields: {
        weeks,
        amount: this.amount.toString(),
        comment: this.comment,
      },
    });
  }

  async remove() {
    DateBriefEntity.deletePenaltyWarning();
  }
}
</script>
