
import { Component, Vue, Prop } from '@/lib/decorator';
import DatePickerMixin from '@/components/form/DatePickerMixin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronLeft, faChevronRight);
@Component({
  mixins: [DatePickerMixin],
  emits: ['prev', 'next'],
})
export default class DateSwitcher extends Vue {
  @Prop({}) data!: () => Record<string, string>;

  handlePrev() {
    this.$emit('prev');
  }

  handleNext() {
    this.$emit('next');
  }
}
