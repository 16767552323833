import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex cp-flex--justify-between align-items-end" }
const _hoisted_3 = { class: "cp-flex align-items-center" }
const _hoisted_4 = {
  key: 2,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_5 = { class: "cp-flex align-items-center" }
const _hoisted_6 = {
  key: 2,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_7 = { class: "cp-flex align-items-center" }
const _hoisted_8 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_date_switcher = _resolveComponent("date-switcher")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_app_table_column_context = _resolveComponent("app-table-column-context")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_phone = _resolveComponent("text-phone")!
  const _component_text_money = _resolveComponent("text-money")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_time_formatter = _resolveComponent("time-formatter")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_shift_modal = _resolveComponent("shift-modal")!
  const _component_edit_penalty = _resolveComponent("edit-penalty")!
  const _component_modal_block = _resolveComponent("modal-block")!
  const _component_edit_bonus = _resolveComponent("edit-bonus")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.date)
              ? (_openBlock(), _createBlock(_component_date_switcher, {
                  key: 0,
                  data: { value: _ctx.date },
                  onPrev: _ctx.handlePrevAction,
                  onNext: _ctx.handleNextAction,
                  onClick: _ctx.updateDate
                }, null, 8, ["data", "onPrev", "onNext", "onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_app_header_main, {
              "is-to-add-hidden": true,
              "filter-settings": _ctx.filterSettings,
              "is-settings-icon": true,
              "is-settings-state": _ctx.isSettingColumn,
              onUpdateList: _ctx.updateList,
              onResetFilters: _ctx.resetFilters,
              onToSettings: _ctx.toSettings,
              onReturnFromSettings: _ctx.closeSettings
            }, null, 8, ["filter-settings", "is-settings-state", "onUpdateList", "onResetFilters", "onToSettings", "onReturnFromSettings"])
          ])
        ]),
        _: 1
      }),
      (!_ctx.dataLoaded)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.headers,
            items: _ctx.settings.table.rows ?? [],
            "is-edit": _ctx.isSettingColumn,
            page: _ctx.settings.table.pagination?.currentPage,
            "total-pages": _ctx.settings.table.pagination?.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "start-drag": _ctx.startDrag,
            "end-drag": _ctx.endDrag,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort,
            onSortHeaders: _ctx.changeVisibleHeaders
          }, {
            top: _withCtx(() => [
              (_ctx.isSettingColumn)
                ? (_openBlock(), _createBlock(_component_app_table_column_context, {
                    key: 0,
                    class: "width-full cp-padding__bsx",
                    ref: "target",
                    headers: _ctx.hiddenHeaders,
                    onSave: _ctx.saveColumnSettings,
                    onCancel: _ctx.cancelColumnSettings
                  }, null, 8, ["headers", "onSave", "onCancel"]))
                : _createCommentVNode("", true)
            ]),
            "item-fio": _withCtx(({ item, value }) => [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.isSupervisor || _ctx.checkIsOwnPartner(item.employeePartnerUuid))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "app-table-link",
                      to: { name: 'employees_schedule', params: { employeeId: item.employeeId } },
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(value), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(value), 1)
                    ], 64)),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-client": _withCtx(({ item, value }) => [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.checkCanReadClient(item.clientId))
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      class: "app-table-link",
                      to: { name: 'customer_edit', params: { customerId: item.clientId } },
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(value), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(value), 1)
                    ], 64)),
                (item.isTestClient)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-market": _withCtx(({ item, value }) => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'shop_shift', params: { shopId: item.marketId } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestMarket)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-vacancy": _withCtx(({ item, value }) => [
              (_ctx.checkCanReadShiftTemplate(item.clientId))
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "app-table-link",
                    to: { name: 'template_edit', params: { customerId: item.clientId, vacancyId: item.vacancyId } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(value), 1)
                  ], 64))
            ]),
            "item-phone": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_phone, {
                    key: 0,
                    value: value
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-status": _withCtx(({ value }) => [
              _createTextVNode(_toDisplayString(_ctx.statuses[value]), 1)
            ]),
            "item-partnerLegalName": _withCtx(({ item, value }) => [
              (_ctx.userCanEditPartner)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    class: "app-table-link",
                    to: { name: 'partner_edit', params: { partnerId: item.partnerUuid } },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(value), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(value), 1)
                  ], 64))
            ]),
            "item-earnedMoney": _withCtx(({ value }) => [
              _createVNode(_component_text_money, { value: value }, null, 8, ["value"])
            ]),
            "item-time": _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: "app-table-link",
                onClick: ($event: any) => (_ctx.openShift(item))
              }, [
                _createVNode(_component_text_datetime, {
                  value: item.start || item.marketShiftStart,
                  "format-to": "H:mm"
                }, null, 8, ["value"]),
                _createTextVNode(" - "),
                _createVNode(_component_text_datetime, {
                  value: item.end || item.marketShiftEnd,
                  "format-to": "H:mm"
                }, null, 8, ["value"])
              ], 8, _hoisted_9)
            ]),
            "item-penaltyMoney": _withCtx(({ item, value }) => [
              (_ctx.checkIsOwnPartner(item.partnerUuid))
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "cp-cursor-pointer",
                    onClick: ($event: any) => (_ctx.editPenalty(item))
                  }, [
                    (+value)
                      ? (_openBlock(), _createBlock(_component_text_money, {
                          key: 0,
                          value: value,
                          class: "app-table-link"
                        }, null, 8, ["value"]))
                      : (_openBlock(), _createBlock(_component_icon_font, {
                          key: 1,
                          class: "app-table-icon app-table-icon--active",
                          icon: "pencil"
                        }))
                  ], 8, _hoisted_10))
                : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                    _createVNode(_component_text_money, { value: value }, null, 8, ["value"])
                  ]))
            ]),
            "item-worked": _withCtx(({ value, item }) => [
              (item.unitType === 'ч')
                ? (_openBlock(), _createBlock(_component_time_formatter, {
                    key: 0,
                    value: value
                  }, null, 8, ["value"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.quantity) + " " + _toDisplayString(item.unitType), 1))
            ]),
            "item-bonusMoney": _withCtx(({ item, value }) => [
              (_ctx.isSupervisor && _ctx.checkIsOwnPartner(item.partnerUuid))
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "cp-cursor-pointer",
                    onClick: ($event: any) => (_ctx.editBonus(item))
                  }, [
                    (+value)
                      ? (_openBlock(), _createBlock(_component_text_money, {
                          key: 0,
                          value: value,
                          class: _normalizeClass({
              'app-table-link': !item.isBonusPaid,
            })
                        }, null, 8, ["value", "class"]))
                      : (_openBlock(), _createBlock(_component_icon_font, {
                          key: 1,
                          class: "app-table-icon app-table-icon--active",
                          icon: "pencil"
                        }))
                  ], 8, _hoisted_13))
                : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                    _createVNode(_component_text_money, { value: value }, null, 8, ["value"])
                  ]))
            ]),
            _: 1
          }, 8, ["headers", "items", "is-edit", "page", "total-pages", "total-items", "page-items", "start-drag", "end-drag", "onSelectAmount", "onSort", "onSortHeaders"])),
      (_ctx.isShowShiftModal)
        ? (_openBlock(), _createBlock(_component_shift_modal, {
            key: 2,
            "shop-id": _ctx.shopId,
            "service-type-id": _ctx.serviceTypeId,
            "shift-id": _ctx.shiftIdForModal,
            onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowShiftModal = false))
          }, null, 8, ["shop-id", "service-type-id", "shift-id"]))
        : _createCommentVNode("", true),
      (_ctx.modalParamsPenalty.penalty.isShow)
        ? (_openBlock(), _createBlock(_component_modal_block, {
            key: 3,
            class: "cp-modal__sm",
            title: _ctx.modalParamsPenalty.penalty.title,
            onClick: _ctx.closeModal,
            notification: _ctx.modalNotification
          }, {
            default: _withCtx(() => [
              _createVNode(_component_edit_penalty, {
                hintMessage: _ctx.hintMessage,
                "penalty-data": _ctx.penaltyData
              }, null, 8, ["hintMessage", "penalty-data"])
            ]),
            _: 1
          }, 8, ["title", "onClick", "notification"]))
        : _createCommentVNode("", true),
      (_ctx.modalParamsPenalty.bonus.isShow)
        ? (_openBlock(), _createBlock(_component_modal_block, {
            key: 4,
            class: "cp-modal__sm",
            title: _ctx.modalParamsPenalty.bonus.title,
            onClick: _ctx.closeModal,
            notification: _ctx.modalNotification
          }, {
            default: _withCtx(() => [
              _createVNode(_component_edit_bonus, {
                hintMessage: _ctx.hintMessage,
                "penalty-data": _ctx.penaltyData
              }, null, 8, ["hintMessage", "penalty-data"])
            ]),
            _: 1
          }, 8, ["title", "onClick", "notification"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}