<template>
  <form ref="form" class="date-brief-form cp-margin__t--small" @submit.prevent="save">
    <div class="cp-form__row cp-page__padding-sm">
      <div class="cp-form__row cp-shift-position-info__wrapper cp-shift-info-icon__wrapper">
        <icon-font class="" size="20" icon="employee" />

        <span v-html="penaltyData.fullName"></span>
      </div>

      <div class="cp-form__row cp-shift-store-info__wrapper cp-shift-info-icon__wrapper">
        <icon-font class="" size="20" icon="store" />

        <span>{{ penaltyData.shopAddress }}</span>
      </div>

      <div class="cp-form__row cp-shift-store-info__wrapper cp-flex--align-end cp-shift-info-icon__wrapper">
        <icon-font class="" size="20" icon="alarm-clock" />

        <span>{{ penaltyData.shiftDate }} ({{ penaltyData.shiftTime }})</span>
      </div>
    </div>

    <div class="cp-form__row cp-page__padding-sm cp-margin__t--small">
      <div class="cp-form__column">
        <form-input
          v-model="amount"
          label="Сумма премии, руб"
          mask="########"
          type="number"
          class="cp-margin__bottom-0"
          :disabled="isBonusPaid"
          required
        />
      </div>

      <div class="cp-form__column">
        <form-select
          v-model="bonus"
          label="Бонус"
          class="max-width--none cp-margin__bottom-0"
          :class="hintMessage ? 'cp-margin__bottom-0' : 'cp-margin__b--small-14'"
          :items="bonusList"
          :disabled="isBonusPaid"
          @update="bonusSelected"
        />
      </div>

      <div v-if="hintMessage" class="cp-color-error cp-text-info">{{ hintMessage }}</div>
    </div>

    <modal-btn-bottom v-if="!isBonusPaid" position="between">
      <form-button :text="text.btnDelete" :bordered="true" :outline="true" @click="remove" class="cp-margin__r--sx" />

      <form-button :text="text.btnSave" :outline="true" type="submit" />
    </modal-btn-bottom>
  </form>
</template>

<script>
import { Component, Vue, Prop, Watch } from '@/lib/decorator';
import { ref } from 'vue';

import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { strings } from '@/lib/stringConst';
import FormInput from '@/components/form/Input/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import formValidate from '@/lib/formFactory/validate';

import DateBriefEntity from '@/store/finance/dateBrief/entity';

@Component({
  components: {
    ModalBtnBottom,
    IconFont,
    FormInput,
    FormButton,
    FormSelect,
  },
})
export default class EditBonus extends Vue {
  @Prop() penaltyData;
  @Prop() hintMessage;

  @Watch('bonusList')
  bonusListChanged(newBonusList) {
    const bonusId = this.penaltyData.bonus;

    if (this.bonus.id || !bonusId || !newBonusList.length) {
      return;
    }

    const currentItem = newBonusList.find(({ id }) => id === bonusId);
    this.bonus = currentItem || this.bonus;
  }

  form = ref(null);
  validate = formValidate(this.form);

  bonus = { id: null, value: '' };
  amount = this.model.amount && this.model.amount != 0 ? this.model.amount * -1 : '';
  isBonusPaid = this.model.isBonusPaid || false;

  get text() {
    return strings;
  }

  get model() {
    return DateBriefEntity.model;
  }

  get bonusList() {
    return DateBriefEntity.bonusList;
  }

  bonusSelected(item) {
    this.amount = item.amount;
  }

  async save(e) {
    DateBriefEntity.saveUpdate({
      type: 'bonus',
      textFields: {
        bonus: this.bonus?.id,
        amount: Math.abs(this.amount),
        weeks: 1,
      },
    });
  }

  async remove() {
    DateBriefEntity.deletePenaltyWarning();
  }

  created() {
    DateBriefEntity.getBonusList(this.penaltyData.employeeShift);
  }
}
</script>
