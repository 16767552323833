import EntityBaseModule from '@/store/entity';
import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { sendPenaltyUpdateData, deletePenaltyData, getPenaltyDetailData } from '@/api/finance';
import ModalsModule from '@/store/modals';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import DateBrief from './index';
import store from '@/store';
import { getAllActiveBonusList } from '@/api/bonus';
import { BonusActiveListItem } from '@/interfaces/bonusResponse.interface';

export const MODULE_NAME = 'dateBriefEntity';

interface TextFields {
  amount: number;
  weeks: string;
  bonus?: number;
  comment?: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class DateBriefEntity extends EntityBaseModule {
  model = {
    amount: '',
    weeks: '',
    comment: '',
  };

  bonusList: BonusActiveListItem[] = [];

  modalParams: Record<string, Record<string, string | boolean | Record<string, string>>>;
  penaltyData: Record<string, string>;

  constructor(module: DateBriefEntity) {
    super(module);

    this.modalParams = {
      penalty: {
        isShow: false,
        title: 'Недостача',
      },
      bonus: {
        isShow: false,
        title: 'Премия',
      },
      deleteWarning: {
        handlers: {
          yes: 'dateBriefEntity/deletePenalty',
        },
        text: 'Вы уверены?',
      },
    };

    this.penaltyData = {
      marketShiftId: '',
      employeeShift: '',
      shiftDate: '',
      shiftTime: '',
      fullName: '',
      shopAddress: '',
      bonus: '',
    };
  }

  @Mutation
  SET_PENALTY_DATA(data: { key: string; value: string }) {
    this.penaltyData[data.key] = data.value;
  }

  @Mutation
  SET_IS_SHOW_PENALTY(bool: boolean) {
    this.modalParams.penalty.isShow = bool;
  }

  @Mutation
  SET_IS_SHOW_BONUS(bool: boolean) {
    this.modalParams.bonus.isShow = bool;
  }

  @Mutation
  SET_BONUS_LIST(list: BonusActiveListItem[]) {
    this.bonusList = list;
  }

  @Action({ rawError: true })
  updatePenaltyData(data: { key: string; value: string }) {
    this.context.commit('SET_PENALTY_DATA', data);
  }

  @Action({ rawError: true })
  async saveUpdate(params: { type: string; textFields: TextFields }) {
    try {
      const data = {
        employeeShift: this.penaltyData.employeeShift,
        amount: params.type === 'penalty' ? params.textFields.amount : params.textFields.amount * -1,
        weeks: params.type === 'penalty' ? params.textFields.weeks : 1,
        bonus: params.textFields.bonus ?? '',
        comment: params.textFields.comment ?? '',
      };

      const result = await sendPenaltyUpdateData(data);

      if (!result.message) {
        let successMessage = '';

        if (params.type === 'penalty') {
          successMessage = 'Задолженность обновлена';
          this.closeModalPenalty();
        }

        if (params.type === 'bonus') {
          successMessage = 'Премия обновлена';
          this.closeModalBonus();
        }

        await DateBrief.getList();

        ResponseHandlerModule.showNotify({ message: successMessage, type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.message ?? error.response.data.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  deletePenaltyWarning() {
    ModalsModule.updateParams(this.modalParams.deleteWarning);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async getPenaltyDetail(employeeShift: number | string) {
    try {
      return await getPenaltyDetailData(employeeShift);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async deletePenalty() {
    try {
      const isShowBonusModal = this.modalParams.bonus.isShow;
      const result = await deletePenaltyData(this.penaltyData.employeeShift);

      if (!result.message) {
        DateBrief.getList();

        let successMessage = 'Задолженность удалена';

        if (isShowBonusModal) {
          successMessage = 'Премия удалена';
          this.closeModalBonus();
        } else {
          this.closeModalPenalty();
        }

        ResponseHandlerModule.showNotify({ message: successMessage, type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.message ?? error.response.data.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  showModalBonus() {
    this.context.commit('SET_IS_SHOW_BONUS', true);
  }

  @Action({ rawError: true })
  closeModalBonus() {
    this.context.commit('SET_IS_SHOW_BONUS', false);
  }

  @Action({ rawError: true })
  showModalPenalty() {
    this.context.commit('SET_IS_SHOW_PENALTY', true);
  }

  @Action({ rawError: true })
  closeModalPenalty() {
    this.context.commit('SET_IS_SHOW_PENALTY', false);
  }

  @Action({ rawError: true })
  async getBonusList(shiftEmployeeId: string) {
    const result: BonusActiveListItem[] = await getAllActiveBonusList(shiftEmployeeId);

    this.context.commit(
      'SET_BONUS_LIST',
      result.map((item: BonusActiveListItem) => ({
        id: item.id,
        name: item.name,
        value: item.name,
        amount: item.amount,
      }))
    );
  }
}

export default getModule(DateBriefEntity);
