/**
 * DateBrief module.
 *
 */
import { watch } from 'vue';
import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import store from '@/store';

import filterModel from './filter';
import PageEntity from '@/lib/layouts/page/pageEntity';

import { getDateBriefList } from '@/api/finance';
import { prepareList } from '@/lib/DateBrief';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

import { getClients } from '@/api/client';
import { getRegionsAll } from '@/api/region';
import { getAllPartnersList } from '@/api/partners';

import moment from 'moment';

export const MODULE_NAME = 'dateBrief';
import { PageSort } from '@/lib/layouts/page/page.interface';
import DateBriefFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class DateBrief extends PageBaseModule {
  dataLoaded = false;
  date: moment.Moment;
  filterDate: string;
  requestDate: string;
  filter: Filter;

  constructor(module: DateBrief) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Сводка по дате');

    this.pageSettings = page.values;
    this.pageSettings.actionPagination = 'dateBrief/updatePage';
    this.pageSettings.actionsHandler = {
      additional: {
        fullName: {
          routeName: 'employee_edit',
          routeParam: 'employeeId',
          routeParamValue: 'employeeId',
        },
        market: {
          routeName: 'shop_edit',
          routeParam: 'shopId',
          routeParamValue: 'marketId',
        },
        start: 'dateBrief/getById',
        end: 'dateBrief/getById',
        client: {
          routeName: 'customer_edit',
          routeParam: 'customerId',
          routeParamValue: 'clientId',
        },
        vacancy: 'dateBrief/getById',
        penaltyMoney: 'dateBrief/getById',
        bonusMoney: 'dateBrief/getById',
        time: 'dateBrief/getShiftById',
      },
    };
    this.date = moment().startOf('day');
    this.filterDate = moment().startOf('day').format('DD/MM/YY');
    this.requestDate = moment().startOf('day').format('YYYY-MM-DD');

    const filter = new filterModel();
    this.filter = getModule(DateBriefFilter);
    this.filter.setFilterName('dateBriefFilter');
    this.filter.setTemplateClassName('template-lg');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor) => {
        if (!isSupervisor) {
          this.filter.resetSelect('partnerUuid');
        }
      },
      { immediate: true }
    );
  }

  get hiddenTitles() {
    return 'hiddenTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.hiddenTitles
      ? this.savedSettings.hiddenTitles[MODULE_NAME]
      : [];
  }

  get orderTitles() {
    return 'orderTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.orderTitles
      ? this.savedSettings.orderTitles[MODULE_NAME]
      : [];
  }

  @Mutation
  setDataLoaded(value: boolean) {
    this.dataLoaded = value;
  }

  @Mutation
  setDate(date: moment.Moment) {
    this.date = date;
  }

  @Mutation
  setFilterDate() {
    this.filterDate = this.date.format('DD/MM/YY');
  }

  @Mutation
  setRequestDate() {
    this.requestDate = this.date.format('YYYY-MM-DD');
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.dateBriefSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.dateBriefSort ? JSON.parse(window.localStorage.dateBriefSort) : '');
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    this.context.commit('setDataLoaded', false);

    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getDateBriefList(
        this.requestDate,
        this.filter.filterSettings.filter,
        itemsQuery,
        sort,
        this.pageSettings.pageCurrent
      );
      await this.setList(result);

      this.context.commit('setDataLoaded', true);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    await this.getSettings();
    const hidden = this.hiddenTitles;
    const order = this.orderTitles;
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, { hidden, order, sort }));
  }

  @Action({ rawError: true })
  async saveSettingsToStore(headers: { hidden: TableHeaderInterface[]; visible: TableHeaderInterface[] }) {
    await this.saveSettings({ name: MODULE_NAME, headers });
  }

  @Action({ rawError: true })
  async initPartnersFilter() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: false })
  async initClients() {
    try {
      const result: { clients: { id: string; name: string }[] } = await getClients();

      return Object.values(result.clients).map((client: { id: string; name: string }) => {
        return {
          id: client.id,
          value: client.name,
        };
      });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: false })
  async initRegions() {
    try {
      const regionsAll: { id: string; name: string }[] = await getRegionsAll();

      return Object.values(regionsAll).map((region: { id: string; name: string }) => {
        return {
          id: region.id,
          value: region.name,
        };
      });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  updateSelectClientList(list: { id: string; value: string }[]) {
    this.context.commit('SET_FILTER_CLIENT_LIST', list);
  }

  @Action({ rawError: true })
  updateSelectRegionList(list: { id: string; value: string }[]) {
    this.context.commit('SET_FILTER_REGION_LIST', list);
  }

  @Action({ rawError: true })
  updateSelectClient(selected: { id: string; value: string }) {
    this.context.commit('SET_FILTER_CLIENT', selected);
  }

  @Action({ rawError: true })
  updateSelectRegion(selected: { id: string; value: string }) {
    this.context.commit('SET_FILTER_REGION', selected);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', '');
  }

  @Action({ rawError: true })
  updateFilterAndRequestDate() {
    this.context.commit('setFilterDate');
    this.context.commit('setRequestDate');
  }

  @Action({ rawError: true })
  switchToPrevDate() {
    this.context.commit('setDate', this.date.subtract(1, 'day'));
    this.context.dispatch('updateFilterAndRequestDate');

    this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  switchToNextDate() {
    this.context.commit('setDate', this.date.add(1, 'day'));
    this.context.dispatch('updateFilterAndRequestDate');

    this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  updateDate(date: string) {
    this.context.commit('setDate', moment(date, 'DD-MM-YY').startOf('day'));
    this.context.dispatch('updateFilterAndRequestDate');

    this.context.dispatch('getList');
  }
}

export default getModule(DateBrief);
